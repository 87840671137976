import type { FC } from 'react';

import {
  AccountIcon,
  CurrencyIcon,
  ExchangeIcon,
  FeesIcon,
  HelpCenterIcon,
  HomeIcon,
  MassPayout24Icon,
  ReferralProgramIcon,
  SettingsIcon,
} from '@kuna-pay/ui/icons';
import type { AtomicRRRoute } from '@kuna-pay/ui/router';
import { JWTCompanyRole } from '@kuna-pay/core/entities/session';

import { CompanyType } from '@kuna-pay/merchant/generated/graphql';
import { routes } from '@kuna-pay/merchant/shared/router';

type NavbarConfigItem = {
  key: string;
  route: AtomicRRRoute;
  icon: FC;
  text: string;
  availableForRoles?: JWTCompanyRole[];
  availableForCompanyTypes?: CompanyType[];
};

class NavbarConfig {
  public static config(isReferralsEnabled: boolean): NavbarConfigItem[] {
    const navColumns = [
      columns.dashboard,
      columns.assets,
      columns.invoices,
      columns.payouts,
      columns.transactions,
      columns.members,
    ];

    if (isReferralsEnabled) {
      navColumns.push(columns.referrals);
    }

    navColumns.push(columns.settings, columns.reporting);

    return navColumns;
  }
}

const columns = {
  dashboard: {
    key: 'dashboard',
    route: routes.dashboard,
    icon: HomeIcon,
    text: `widgets.layout.navbar.dashboard`,
  },

  assets: {
    key: 'assets',
    route: routes.assets.list,
    icon: CurrencyIcon,
    text: `widgets.layout.navbar.assets`,
    availableForRoles: [
      JWTCompanyRole.Owner,
      JWTCompanyRole.Admin,
      JWTCompanyRole.Accountant,
    ],
  },

  invoices: {
    key: 'invoices',
    route: routes.invoices.list,
    icon: FeesIcon,
    text: `widgets.layout.navbar.invoices`,
  },

  payouts: {
    key: 'payouts',
    route: routes.payouts.list,
    icon: MassPayout24Icon,
    text: `widgets.layout.navbar.payouts`,

    availableForRoles: [JWTCompanyRole.Owner, JWTCompanyRole.Admin],

    availableForCompanyTypes: [
      CompanyType.LegalEntity,
      CompanyType.Entrepreneur,
    ],
  },

  transactions: {
    key: 'transactions',
    route: routes.transactions.list,
    icon: ExchangeIcon,
    text: `widgets.layout.navbar.transactions`,
  },

  members: {
    key: 'members',
    route: routes.members.list,
    icon: AccountIcon,
    text: `widgets.layout.navbar.members`,

    availableForRoles: [JWTCompanyRole.Owner, JWTCompanyRole.Admin],

    availableForCompanyTypes: [
      CompanyType.LegalEntity,
      CompanyType.Entrepreneur,
    ],
  },

  referrals: {
    key: 'referrals',
    route: routes.referrals.list,
    icon: ReferralProgramIcon,
    text: `widgets.layout.navbar.referrals`,

    availableForRoles: [JWTCompanyRole.Owner, JWTCompanyRole.Admin],
  },

  settings: {
    key: 'settings',
    route: routes.settings.root,
    icon: SettingsIcon,
    text: `widgets.layout.navbar.settings`,
  },

  reporting: {
    key: 'reporting',
    route: routes.reporting.root,
    icon: HelpCenterIcon,
    text: `widgets.layout.navbar.reporting`,
  },
} satisfies Record<string, NavbarConfigItem>;

export { NavbarConfig };
