import { AtomicRRRoute } from '@kuna-pay/ui/router';

const routes = {
  auth: {
    signIn: new AtomicRRRoute('/sign-in'),
    signUp: new AtomicRRRoute('/sign-up'),
    blocked: new AtomicRRRoute('/blocked'),
    migration: new AtomicRRRoute('/migration'),
    sso: new AtomicRRRoute('/sso'),

    email: {
      confirm: new AtomicRRRoute('/confirm-email'),
      confirmed: new AtomicRRRoute('/confirm-email/confirmed'),
    },
    mfa: {
      activated: new AtomicRRRoute('/2fa/activated'),
      turnOn: new AtomicRRRoute('/2fa/turn-on'),
    },

    verification: {
      start: new AtomicRRRoute('/verification'),
      done: new AtomicRRRoute('/verification/done'),
      alreadyVerified: new AtomicRRRoute('/verification/already-verified'),
    },

    resetPassword: {
      change: new AtomicRRRoute('/reset-password'),

      request: new AtomicRRRoute('/reset-password/request'),
      requested: new AtomicRRRoute<{ email: string }>(
        '/reset-password/requested'
      ),
      changeOutdated: new AtomicRRRoute('/reset-password/outdated'),
      changeFailed: new AtomicRRRoute('/reset-password/change/fail'),
      changeSucceed: new AtomicRRRoute('/reset-password/change/success'),
    },
  },

  dashboard: new AtomicRRRoute('/dashboard'),

  merchant: {
    list: new AtomicRRRoute('/'),
    create: new AtomicRRRoute('/merchant/create'),
    created: new AtomicRRRoute('/merchant/created'),
  },
  assets: {
    list: new AtomicRRRoute<{ deposit?: string }>('/assets'),
  },
  invoices: {
    list: new AtomicRRRoute('/invoices'),
    details: new AtomicRRRoute<{ id: string }>('/invoices/:id'),
    create: new AtomicRRRoute('/invoices/create'),
  },
  payouts: {
    list: new AtomicRRRoute('/payouts'),
    details: new AtomicRRRoute<{ id: string; launched?: boolean }>(
      '/payouts/:id'
    ),
    create: new AtomicRRRoute('/payouts/create'),
    launch: new AtomicRRRoute<{ id: string; from?: string }>(
      '/payouts/launch/:id'
    ),
  },
  transactions: {
    list: new AtomicRRRoute('/transactions'),
    details: new AtomicRRRoute<{ id: string }>('/transactions/:id'),
    processing: new AtomicRRRoute<{ id: string }>(
      '/transactions/processing/:id'
    ),
  },
  members: {
    list: new AtomicRRRoute('/members'),
  },
  referrals: {
    list: new AtomicRRRoute('/referrals'),
  },
  settings: {
    root: new AtomicRRRoute('/settings'),
    account: new AtomicRRRoute('/settings/account'),
    apiKey: new AtomicRRRoute('/settings/api-key'),
    autoConversion: new AtomicRRRoute('/settings/auto-conversion'),
    paymentsCustomization: new AtomicRRRoute(
      '/settings/payments-customization'
    ),
    exchangeRate: new AtomicRRRoute('/settings/exchange-rate'),
  },

  profile: {
    root: new AtomicRRRoute('/profile'),
    main: new AtomicRRRoute('/profile/main'),
    notifications: new AtomicRRRoute('/profile/notifications'),
    security: new AtomicRRRoute('/profile/security'),
  },

  reporting: {
    root: new AtomicRRRoute('/reporting'),
    settlement: new AtomicRRRoute('/reporting/settlement'),
    statement: new AtomicRRRoute('/reporting/statement'),
  },

  companyFees: {
    root: new AtomicRRRoute('/company-fees'),
    invoiceDeposit: new AtomicRRRoute('/company-fees/invoice-deposit'),
    withdrawal: new AtomicRRRoute('/company-fees/withdrawal'),
    payout: new AtomicRRRoute('/company-fees/payout'),
    deposit: new AtomicRRRoute('/company-fees/deposit'),
    convert: new AtomicRRRoute('/company-fees/convert'),
  },
};

export { routes };
