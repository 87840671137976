import { combine, createEvent, createStore, sample } from 'effector';
import { persist } from 'effector-storage/session';
import type { Location } from 'react-router-dom';
import { matchPath } from 'react-router-dom';
import { string } from 'yup';

import { atom, bridge } from '@kuna-pay/utils/misc';
import type { IAtomicRRRoute } from '@kuna-pay/ui/router';

import { $$api } from '@kuna-pay/merchant/shared/api';
import { routes } from '@kuna-pay/merchant/shared/router';

import { createSearchParamsCollector } from '../lib/search-params-collector';

const PERSIST_CONFIG = {
  PATH: 'REDIRECT_ON_SIGN_IN',
  COMPANY: 'AUTO_LOGIN_COMPANY_ID',
};

const URL_QUERY_PARAMS_CONFIG = {
  COMPANY_ID: 'company_id',
};

const $$redirectOnSignIn = atom(() => {
  const $path = createStore<string | null>(null);

  const reset = createEvent();
  const savePath = createEvent<string>();

  bridge(() => {
    $path.on(savePath, (current, path) =>
      shouldSaveForRedirect(path) ? path : current
    );
  });

  // Save on logout from api
  // This happens when tokens are expired and not on page open
  bridge(() => {
    sample({
      clock: $$api.events.loggedOut,
      fn: () => window.location.pathname,
      target: savePath,
    });
  });

  bridge(() => {
    persist({ store: $path, key: PERSIST_CONFIG.PATH });
  });

  bridge(() => {
    $path.reset(reset);
  });

  return {
    $hasSavedRedirectPath: combine($path, (path) => Boolean(path)),

    useCompanyIdCollector: createSearchParamsCollector({
      searchParamKey: URL_QUERY_PARAMS_CONFIG.COMPANY_ID,
      storageKey: PERSIST_CONFIG.COMPANY,
      storage: sessionStorage,
    }),

    getCompanyId: async () => {
      const maybeCompanyId = sessionStorage.getItem(PERSIST_CONFIG.COMPANY);

      if (string().uuid().isValidSync(maybeCompanyId)) {
        return maybeCompanyId;
      }

      return null;
    },

    $path,

    savePath,
    reset,

    URL_QUERY_PARAMS_CONFIG,
  };
});

const allowedRoutes: IAtomicRRRoute[] = [
  routes.dashboard,
  routes.assets.list,
  routes.transactions.list,
  routes.transactions.details,
  routes.transactions.processing,
  routes.invoices.list,
  routes.invoices.details,
  routes.members.list,
  routes.settings.account,
  routes.settings.apiKey,
  routes.settings.autoConversion,
  routes.settings.exchangeRate,
  routes.reporting.root,
  routes.reporting.settlement,
  routes.reporting.statement,
  routes.profile.main,
  routes.profile.security,
  routes.profile.notifications,
  routes.companyFees.root,
  routes.companyFees.deposit,
  routes.companyFees.invoiceDeposit,
  routes.companyFees.withdrawal,
  routes.companyFees.convert,
  routes.companyFees.payout,
  routes.referrals.list,
];

function shouldSaveForRedirect(pathname: Location['pathname']) {
  const shouldSave = allowedRoutes.some((route) => {
    const match = matchPath(route, pathname);

    return !!match;
  });

  return shouldSave;
}

export { $$redirectOnSignIn };
