import { useUnit } from 'effector-react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useMatch } from 'react-router-dom';

import { useMediaQuery } from '@kuna-pay/utils/react/use-media-query';
import {
  ArrowLeftIcon,
  Bell24Icon,
  Lock24Icon,
  Profile24Icon,
} from '@kuna-pay/ui/icons';
import { Navigate } from '@kuna-pay/ui/router';
import { Link } from '@kuna-pay/ui/router';
import { Tabs } from '@kuna-pay/ui/ui/tabs';
import { Typography } from '@kuna-pay/ui/ui/typography';

import { $$optional2FAFeatureFlag } from '@kuna-pay/merchant/shared/feature-flags/features/static';
import { routes } from '@kuna-pay/merchant/shared/router';
import { NavButton } from '@kuna-pay/merchant/shared/ui/nav-button';
import { MainLayout } from '@kuna-pay/merchant/widgets/layout';

import { ProfileTab } from './page.config';
import styles from './page.module.scss';

const ProfilePage = memo(() => {
  const match = useMatch('/profile/:tab');
  const tab = match?.params?.tab as ProfileTab.Notifications;
  const { t } = useTranslation();
  const isTabletOrLess = useMediaQuery('(max-width: 1024px)');
  const optional2FAFeatureFlag = useUnit($$optional2FAFeatureFlag);

  if (!tab) {
    if (isTabletOrLess) {
      return (
        <MainLayout.Merchant.Content className={styles.root}>
          <Typography className={styles.title} variant='h6'>
            {t('pages.profile.host.title')}
          </Typography>

          <div className={styles.navigation}>
            <NavButton to={routes.profile.main} icon={<Profile24Icon />}>
              {t('pages.profile.host.tabs.main')}
            </NavButton>

            {optional2FAFeatureFlag.enabled && (
              <NavButton to={routes.profile.security} icon={<Lock24Icon />}>
                {t('pages.profile.host.tabs.security')}
              </NavButton>
            )}

            <NavButton to={routes.profile.notifications} icon={<Bell24Icon />}>
              {t('pages.profile.host.tabs.notifications')}
            </NavButton>
          </div>
        </MainLayout.Merchant.Content>
      );
    }

    return <Navigate to={routes.profile.main} />;
  }

  if (isTabletOrLess) {
    return (
      <MainLayout.Merchant.Content className={styles.root}>
        <div>
          <Link className={styles.pageTitle} to={routes.profile.root}>
            <ArrowLeftIcon />

            <TabTitle tab={tab} />
          </Link>
        </div>

        <Outlet />
      </MainLayout.Merchant.Content>
    );
  }

  return (
    <MainLayout.Merchant.Content>
      <Typography className={styles.title} variant='h6'>
        {t('pages.profile.host.title')}
      </Typography>

      <Tabs className={styles.tabs} value={tab} variant='linear'>
        <Tabs.List className={styles.tabList}>
          <Tabs.Trigger value={ProfileTab.MainInfo} asChild>
            <Link.ForwardRef to={routes.profile.main}>
              {t('pages.profile.host.tabs.main')}
            </Link.ForwardRef>
          </Tabs.Trigger>

          {optional2FAFeatureFlag.enabled && (
            <Tabs.Trigger value={ProfileTab.Security} asChild>
              <Link.ForwardRef to={routes.profile.security}>
                {t('pages.profile.host.tabs.security')}
              </Link.ForwardRef>
            </Tabs.Trigger>
          )}

          <Tabs.Trigger value={ProfileTab.Notifications} asChild>
            <Link.ForwardRef to={routes.profile.notifications}>
              {t('pages.profile.host.tabs.notifications')}
            </Link.ForwardRef>
          </Tabs.Trigger>
        </Tabs.List>

        <Tabs.Content className={styles.content} value={tab}>
          <Outlet />
        </Tabs.Content>
      </Tabs>
    </MainLayout.Merchant.Content>
  );
});

type TabTitleProps = {
  tab: ProfileTab;
};

const TabTitle = memo(({ tab }: TabTitleProps) => {
  const { t } = useTranslation();

  switch (tab) {
    case ProfileTab.MainInfo: {
      return (
        <Typography variant='h6'>
          {t('pages.profile.host.tabs.main')}
        </Typography>
      );
    }

    case ProfileTab.Notifications: {
      return (
        <Typography variant='h6'>
          {t('pages.profile.host.tabs.notifications')}
        </Typography>
      );
    }

    case ProfileTab.Security: {
      return (
        <Typography variant='h6'>
          {t('pages.profile.host.tabs.security')}
        </Typography>
      );
    }

    default: {
      return null;
    }
  }
});

export { ProfilePage };
