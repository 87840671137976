import { createDynamicFlag } from '../model/dynamic.factory';

const $$testDepositFeatureFlag = createDynamicFlag(
  'KUPAY_1738_MERCHANT_TEST_DEPOSIT'
);

const $$rateYourExperienceFeedbackFeatureFlag = createDynamicFlag(
  'KUPAY_2293_FE_RATE_YOUR_EXPERIENCE_FEEDBACK'
);

const $$createCompanyIndustryFeatureFlag = createDynamicFlag(
  'KUPAY_2677_FE_MERCHANT_CREATE_COMPANY_INDUSTRY'
);

const $$referralsFeatureFlag = createDynamicFlag(
  'KUPAY_1680_STORY_PARTNER_PROGRAM'
);

export {
  $$createCompanyIndustryFeatureFlag,
  $$rateYourExperienceFeedbackFeatureFlag,
  $$referralsFeatureFlag,
  $$testDepositFeatureFlag,
};
