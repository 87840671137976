import { useUnit } from 'effector-react';
import { Fragment, memo, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Divider } from '@kuna-pay/ui/ui/divider';

import { CanAccess } from '@kuna-pay/merchant/entities/session';
import { $$referralsFeatureFlag } from '@kuna-pay/merchant/shared/feature-flags/features/dynamic';

import { HeaderMenuContext } from '../header-menu/header-menu.context';
import { Nav } from './nav';
import { NavbarConfig } from './navbar.config';
import { NavbarGate } from './navbar.context';

const MobileNavbar = memo(({ className }: { className?: string }) => {
  const { t } = useTranslation();
  const closeMobileDrawer = useContext(HeaderMenuContext);
  const isInCompanyContext = useUnit(NavbarGate.status);

  const referralsFeatureFlag = useUnit($$referralsFeatureFlag);
  const navbarConfig = useMemo(
    () => NavbarConfig.config(referralsFeatureFlag.enabled),
    [referralsFeatureFlag.enabled]
  );

  if (!isInCompanyContext) {
    return null;
  }

  return (
    <>
      {navbarConfig.map((item) => {
        let JSX = (
          <Nav.Item.Root
            className={className}
            to={item.route}
            title={t(item.text)}
            onClick={closeMobileDrawer}
          >
            <Nav.Item.Icon>
              <item.icon />
            </Nav.Item.Icon>

            <Nav.Item.Text>{t(item.text)}</Nav.Item.Text>
          </Nav.Item.Root>
        );

        if (item.availableForRoles) {
          JSX = (
            <CanAccess.Company.Role role={item.availableForRoles}>
              {JSX}
            </CanAccess.Company.Role>
          );
        }

        if (item.availableForCompanyTypes) {
          JSX = (
            <CanAccess.Company.Type type={item.availableForCompanyTypes}>
              {JSX}
            </CanAccess.Company.Type>
          );
        }

        return <Fragment key={item.key}>{JSX}</Fragment>;
      })}

      <Divider />
    </>
  );
});

export { MobileNavbar };
