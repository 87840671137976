import { useUnit } from 'effector-react';
import { Fragment, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CanAccess } from '@kuna-pay/merchant/entities/session';
import { $$referralsFeatureFlag } from '@kuna-pay/merchant/shared/feature-flags/features/dynamic';

import { Nav } from './nav';
import { NavbarConfig } from './navbar.config';

const DesktopNavbar = memo(() => {
  const { t } = useTranslation();

  const referralsFeatureFlag = useUnit($$referralsFeatureFlag);
  const navbarConfig = useMemo(
    () => NavbarConfig.config(referralsFeatureFlag.enabled),
    [referralsFeatureFlag.enabled]
  );

  return (
    <Nav.DesktopRoot>
      {navbarConfig.map((item) => {
        let JSX = (
          <Nav.Item.Root to={item.route} title={t(item.text)}>
            <Nav.Item.Icon>
              <item.icon />
            </Nav.Item.Icon>

            <Nav.Item.Text>{t(item.text)}</Nav.Item.Text>
          </Nav.Item.Root>
        );

        if (item.availableForRoles) {
          JSX = (
            <CanAccess.Company.Role role={item.availableForRoles}>
              {JSX}
            </CanAccess.Company.Role>
          );
        }

        if (item.availableForCompanyTypes) {
          JSX = (
            <CanAccess.Company.Type type={item.availableForCompanyTypes}>
              {JSX}
            </CanAccess.Company.Type>
          );
        }

        return <Fragment key={item.key}>{JSX}</Fragment>;
      })}
    </Nav.DesktopRoot>
  );
});

export { DesktopNavbar };
