import { useUnit } from 'effector-react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Profile24Icon, User24Icon } from '@kuna-pay/ui/icons';
import { Link } from '@kuna-pay/ui/router';
import { CopyButton, UnstyledButton } from '@kuna-pay/ui/ui/button';
import { Divider } from '@kuna-pay/ui/ui/divider';
import { Skeleton } from '@kuna-pay/ui/ui/skeleton';
import { Typography } from '@kuna-pay/ui/ui/typography';

import { $$session } from '@kuna-pay/merchant/entities/session';
import { routes } from '@kuna-pay/merchant/shared/router';

import { ReactComponent as SignOutIcon } from '../../../assets/logout.svg';
import { $$headerMenu } from '../header-menu.model';
import { HeaderMenuDropdown } from '../ui';
import { UserMenuGate } from './user-menu.context';
import styles from './user-menu.module.scss';

const DesktopUserMenu = memo(() => {
  const { t } = useTranslation();

  const isGateMounted = useUnit(UserMenuGate.status);

  const [
    isLoggedIntoSystem,
    isLoggingIntoSystem,
    isLoggedIntoCompany,
    onLogoutClick,
  ] = useUnit([
    $$headerMenu.$$userMenu.$isSystemLogged,
    $$headerMenu.$$userMenu.$isSystemLoggedPending,
    $$headerMenu.$$userMenu.$isCompanyLogged,
    $$headerMenu.$$userMenu.$$logout.logout,
  ]);

  if (!isGateMounted) {
    return null;
  }

  if (!isLoggedIntoSystem) {
    if (isLoggingIntoSystem) {
      return <Skeleton height={24} width={24} />;
    }

    return null;
  }

  return (
    <HeaderMenuDropdown
      className={styles.root}
      content={
        <>
          <UserInfo />

          <div className={styles.navigation}>
            {isLoggedIntoCompany && (
              <HeaderMenuDropdown.Item.Root
                className={styles.navigationItem}
                asChild
              >
                <Link to={routes.profile.root}>
                  <User24Icon />

                  <HeaderMenuDropdown.Item.Text>
                    {t('widgets.layout.header-menu.user-menu.profile')}
                  </HeaderMenuDropdown.Item.Text>
                </Link>
              </HeaderMenuDropdown.Item.Root>
            )}

            <HeaderMenuDropdown.Item
              className={styles.navigationItem}
              onClick={onLogoutClick}
              start={<SignOutIcon />}
              iconSize='24'
            >
              {t('widgets.layout.header-menu.user-menu.logout')}
            </HeaderMenuDropdown.Item>
          </div>
        </>
      }
    >
      <UnstyledButton className={styles.avatar}>
        <Profile24Icon />
      </UnstyledButton>
    </HeaderMenuDropdown>
  );
});

const UserInfo = memo(() => {
  const { t } = useTranslation();

  const [email, companyId, isLoggedIntoCompany] = useUnit([
    $$session.$$jwt.$$user.$email,
    $$session.$$jwt.$$company.$id,
    $$headerMenu.$$userMenu.$isCompanyLogged,
  ]);

  if (!email && !companyId) {
    return null;
  }

  return (
    <>
      <div className={styles.userInfo}>
        {!!email && (
          <Typography variant='subtitle2' title={email} nowrap>
            {email}
          </Typography>
        )}

        {isLoggedIntoCompany && (
          <>
            {!!companyId && (
              <div className={styles.userInfoCompanyId}>
                <Typography className='text-black400' variant='numbers1'>
                  {t('widgets.layout.header-menu.user-menu.info.company-id')}
                </Typography>

                <CopyButton
                  classes={{
                    root: styles.userInfoCompanyIdCopyRoot,
                    button: styles.userInfoCompanyIdCopyButton,
                  }}
                  value={companyId}
                  customMessage={t(
                    'widgets.layout.header-menu.user-menu.info.company-id-copy-message'
                  )}
                >
                  <Typography variant='numbers1' title={companyId}>
                    {companyId}
                  </Typography>
                </CopyButton>
              </div>
            )}
          </>
        )}
      </div>

      <Divider />
    </>
  );
});

export { DesktopUserMenu, UserInfo };
