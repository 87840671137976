import { createStaticFeatureFlag } from '../model/static.factory';

const $$isPLLangEnabledFeatureFlag = createStaticFeatureFlag(
  'KUPAY_1936_FE_MERCHANT_PL_LANGUAGE_ENABLED'
);

const $$isESLangEnabledFeatureFlag = createStaticFeatureFlag(
  'KUPAY_1936_FE_MERCHANT_ES_LANGUAGE_ENABLED'
);

const $$crowdinInContextFeatureFlag = createStaticFeatureFlag(
  'FE_MERCHANT_CROWDIN_IN_CONTEXT'
);

const $$kunaProAuthFeatureFlag = createStaticFeatureFlag(
  'KUPAY_1479_IS_KUNA_PRO_AUTH_ENABLED'
);

const $$recaptchaFeatureFlag = createStaticFeatureFlag('RECAPTCHA_ENABLED');

const $$googleAuthWithSignUpFeatureFlag = createStaticFeatureFlag(
  'KUPAY_2347_STORY_CREATE_PROFILE_IF_NOT_EXIST_BY_GOOGLE_AUTH'
);

const $$genericFeedbackFeatureFlag = createStaticFeatureFlag(
  'KUPAY_2293_FE_MERCHANT_GENERIC_FEEDBACK'
);

const $$collectRefForSignUpFeatureFlag = createStaticFeatureFlag(
  'KUPAY_1680_STORY_PARTNER_PROGRAM'
);

const $$optional2FAFeatureFlag = createStaticFeatureFlag(
  'KUPAY_1680_STORY_PARTNER_PROGRAM'
);

export {
  $$collectRefForSignUpFeatureFlag,
  $$crowdinInContextFeatureFlag,
  $$genericFeedbackFeatureFlag,
  $$googleAuthWithSignUpFeatureFlag,
  $$isESLangEnabledFeatureFlag,
  $$isPLLangEnabledFeatureFlag,
  $$kunaProAuthFeatureFlag,
  $$optional2FAFeatureFlag,
  $$recaptchaFeatureFlag,
};
