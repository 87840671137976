import { memo } from 'react';
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
} from 'react-router-dom';

import { RouterErrorBoundary } from '@kuna-pay/ui/error-boundary';

import {
  $$redirectOnSignIn,
  $$referral,
  CompanyAuthorizedGuard,
  EmailConfirmedGuard,
  MFAEnabledGuard,
  NonExpiredTokensGuard,
  RedirectOnSignInCatchall,
  SystemAuthorizedGuard,
} from '@kuna-pay/merchant/entities/session';
import { AuthFeaturesGuard } from '@kuna-pay/merchant/shared/feature-flags';
import { $$router } from '@kuna-pay/merchant/shared/router';
import { MainLayout } from '@kuna-pay/merchant/widgets/layout';

import { AssetPages } from './assets';
import { AuthPages } from './auth/auth.routes';
import { ConfirmEmailPages } from './auth/email';
import { MFAPages } from './auth/mfa';
import { DashboardPages } from './dashboard';
import { CompanyFeesPages } from './fees';
import { InvoicePages } from './invoices';
import { MembersPages } from './members';
import { MerchantPages } from './merchant';
import { PayoutPages } from './payouts';
import { ProfilePages } from './profile';
import { ReferralsPages } from './referrals';
import { ReportingPages } from './reporting';
import { SettingsPages } from './settings';
import { TransactionNavbarlessPages, TransactionPages } from './transactions';
import { VerificationPages } from './verification';

const router = createBrowserRouter([
  {
    Component: () => {
      $$router.useSetup();
      $$referral.useRefCollector();
      $$redirectOnSignIn.useCompanyIdCollector();

      return <Outlet />;
    },

    ErrorBoundary: RouterErrorBoundary,

    children: [
      {
        element: (
          <MainLayout>
            <Outlet />
          </MainLayout>
        ),

        children: [
          ...AuthPages,

          {
            element: (
              <NonExpiredTokensGuard>
                <MainLayout.HeaderMenu.UserMenu.Gate />

                <Outlet />
              </NonExpiredTokensGuard>
            ),

            children: [
              ...ConfirmEmailPages,

              {
                element: (
                  <EmailConfirmedGuard>
                    <Outlet />
                  </EmailConfirmedGuard>
                ),

                children: [
                  ...MFAPages,

                  {
                    element: (
                      <RedirectOnSignInCatchall>
                        <MFAEnabledGuard>
                          <SystemAuthorizedGuard>
                            <AuthFeaturesGuard>
                              <Outlet />
                            </AuthFeaturesGuard>
                          </SystemAuthorizedGuard>
                        </MFAEnabledGuard>
                      </RedirectOnSignInCatchall>
                    ),
                    children: [
                      ...MerchantPages,

                      {
                        element: (
                          <MainLayout.Merchant>
                            <CompanyAuthorizedGuard>
                              <MainLayout.ExceedLimitAlert.Gate />
                              <MainLayout.Navbar.Gate />

                              <Outlet />
                            </CompanyAuthorizedGuard>
                          </MainLayout.Merchant>
                        ),
                        children: [
                          ...VerificationPages,

                          ...TransactionNavbarlessPages,

                          {
                            Component: () => (
                              <>
                                <MainLayout.Navbar.Desktop />

                                <Outlet />
                              </>
                            ),

                            children: [
                              ...DashboardPages,
                              ...AssetPages,
                              ...InvoicePages,
                              ...PayoutPages,
                              ...TransactionPages,
                              ...MembersPages,
                              ...ReportingPages,
                              ...SettingsPages,
                              ...ProfilePages,
                              ...CompanyFeesPages,
                              ...ReferralsPages,
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: '*',
        element: <Navigate to='/' replace />,
      },
    ],
  },
]);

const Pages = memo(() => <RouterProvider router={router} />);

export { Pages };
